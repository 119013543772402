import { axiosInstance } from '.'
import { InvestmentStatus } from '../lib/types'
import { removeEmptyValues } from '../lib/utils/helpers'
import {
  GET_INVESTMENT_BY_PROJECT_ID,
  GET_USER_INVESTMENT,
  POST_PROJECT_INVESTMENT,
  SEARCH_INVESTMENTS,
  UPDATE_PROJECT_INVESTMENT,
  GET_INVESTMENT_METRICS,
  GET_QUARTERLY_REPORT,
  GET_INVESTMENT_LATEST,
  GET_USER_INVESTMENT_V1,
  GET_INVESTMENT_LATEST_V1,
  GET_INVESTMENT_BY_OPPORTUNITY_ID,
  ADD_OPPORTUNITY_INVESTMENT,
  UPDATE_OPPORTUNITY_INVESTMENT,
  SEARCH_INVESTMENTS_V1,
  GET_INVESTMENT_METRICS_V1,
  GET_INVESTMENT_DOCUMENT,
  GET_SIGNED_DOCUMENT,
} from './endpoints'
import { InvestmentSearchParams, UpdateInvestment } from './types'

export const investServices = {
  getInvestmentByProjectId: async (
    projectId: any,
    status: InvestmentStatus,
    headers: { [key: string]: string },
  ) => {
    try {
      const response = await axiosInstance.get(
        `${GET_INVESTMENT_BY_PROJECT_ID}/${projectId}/${status}`,
        {
          headers: headers,
        },
      )
      return {
        data: response.data.length > 0 ? response.data[0] : undefined,
        status: response.status,
      }
    } catch (error: any) {
      return error.response
    }
  },
  getUserInvestmentDetail: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_USER_INVESTMENT, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  postProjectInvestment: async (payload: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(
        POST_PROJECT_INVESTMENT,
        {
          ...payload,
          projectId: payload?.projectId && parseInt(payload.projectId),
          investmentAmount: payload?.investmentAmount && parseInt(payload.investmentAmount) * 100,
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },
  updateProjectInvestment: async (payload: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(
        UPDATE_PROJECT_INVESTMENT,
        {
          ...payload,
          projectId: payload?.projectId && parseInt(payload.projectId),
          userId: payload?.userId && parseInt(payload.userId),
          investmentAmount: payload?.investmentAmount && parseInt(payload.investmentAmount) * 100,
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },
  updateInvestment: async (payload: UpdateInvestment, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(UPDATE_PROJECT_INVESTMENT, payload, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
  searchInvestments: async (params: InvestmentSearchParams, headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(SEARCH_INVESTMENTS, {
        params: removeEmptyValues(params),
        headers: headers,
      })
      return {
        ...data.data,
        investments: data.data.investments,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
    } catch (error: any) {
      return error.response
    }
  },
  getInvestmentMetrics: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_INVESTMENT_METRICS, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },
  getInvestmentQuarterlyReport: async (
    params: { investorId: number },
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(GET_QUARTERLY_REPORT, {
        headers: headers,
        params: params,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },
  getInvestmentLatest: async (
    params: { projectId: number },
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(GET_INVESTMENT_LATEST, {
        params: params,
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  // v1
  getUserInvestmentDetail_v1: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_USER_INVESTMENT_V1, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentLatest_v1: async (
    params: { opportunityId: number },
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(GET_INVESTMENT_LATEST_V1, {
        params: params,
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentByOpportunityId: async (
    projectId: any,
    status: InvestmentStatus,
    headers: { [key: string]: string },
  ) => {
    try {
      const response = await axiosInstance.get(
        `${GET_INVESTMENT_BY_OPPORTUNITY_ID}/${projectId}/${status}`,
        {
          headers: headers,
        },
      )
      return {
        data: response.data,
        status: response.status,
      }
    } catch (error: any) {
      return error.response
    }
  },

  postOpportunityInvestment: async (payload: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.post(
        ADD_OPPORTUNITY_INVESTMENT,
        {
          opportunity_id: payload?.projectId && parseInt(payload.projectId),
          amount: payload?.investmentAmount && parseInt(payload.investmentAmount) * 100,
          metadata: payload?.metadata,
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },

  updateOpportunityInvestment: async (payload: any, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.patch(
        UPDATE_OPPORTUNITY_INVESTMENT,
        {
          id: payload.investmentId,
          investor_id: payload?.userId && parseInt(payload.userId),
          opportunity_id: payload?.projectId && parseInt(payload.projectId),
          amount: payload?.investmentAmount && parseInt(payload.investmentAmount) * 100,
          status: payload.status ?? 'PENDING',
          metadata: payload.metadata,
        },
        {
          headers: headers,
        },
      )
    } catch (error: any) {
      return error.response
    }
  },

  searchInvestments_v1: async (
    params: InvestmentSearchParams,
    headers: { [key: string]: string },
  ) => {
    try {
      const data = await axiosInstance.get(SEARCH_INVESTMENTS_V1, {
        params: removeEmptyValues(params),
        headers: headers,
      })
      return {
        ...data.data,
        investments: data.data.investments,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentMetrics_v1: async (headers: { [key: string]: string }) => {
    try {
      const data = await axiosInstance.get(GET_INVESTMENT_METRICS_V1, {
        headers: headers,
      })
      return data
    } catch (error: any) {
      return error.response
    }
  },

  getInvestmentDocument: async (documentId: string, headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_INVESTMENT_DOCUMENT(documentId), {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getInvestorSignedAgreement: async (headers: { [key: string]: string }) => {
    try {
      return await axiosInstance.get(GET_SIGNED_DOCUMENT, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
}
