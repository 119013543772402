import React from 'react'

import { CheckboxInput } from '../Input'

const InvestmentEssentialsForm = ({
  handleCheckboxClick,
  isChecked,
}: {
  handleCheckboxClick: () => void
  isChecked: boolean
}) => {
  return (
    <div className='flex gap-12'>
      <CheckboxInput
        labelText='I Agree'
        name='acceptInvestmentEssentialsAgreement'
        checked={isChecked}
        disabled={isChecked}
        value={isChecked ? 'true' : 'false'}
        handleChange={handleCheckboxClick}
      />
    </div>
  )
}

export { InvestmentEssentialsForm }
