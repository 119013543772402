import React, { useEffect, useState } from 'react'
import './dashboard.scss'
import { dashboardServices } from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { DashboardPresenter } from './dashboard-presenter'
import { DASHBOARD_VIEW_OPTIONS } from '../../lib/utils/constants'
import { ActiveInvestmentPresenter } from './active-investment-presenter'
import {
  DashboardActiveInvestmentPortfolio,
  DashboardSummaryProps,
  DashboardActiveInvestment,
} from '../../lib/types'

type View = (typeof DASHBOARD_VIEW_OPTIONS)[number]

const DashboardContainerNew = () => {
  const [activeView, setActiveView] = useState<View>('portfolioSummary')
  const [activeInvestment, setActiveInvestment] = useState<null | string>(null)
  const [summary, setSummary] = useState<DashboardSummaryProps>({
    totalInvestmentAmount: 0,
    totalReturnsTillDate: 0,
  })
  const [activeInvestmentsNew, setActiveInvestments] = useState<DashboardActiveInvestmentPortfolio>(
    {
      averageReturn: 0,
      currentInvestmentValue: 0,
      investments: [],
    },
  )
  const { authToken } = useSelector((state: RootState) => state.investor)

  // Hook for fetching dashboard summary and investments related data
  useEffect(() => {
    const fetchDashboardSummary = async () => {
      const data: any = await dashboardServices.getDashboardSummary({
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        setSummary(data.data)
      }
    }

    const fetchDashboardActiveInvestments = async () => {
      const data: any = await dashboardServices.getActiveInvestments({
        Authorization: authToken ? authToken : '',
      })
      if (data && data.data && data.status === 200) {
        setActiveInvestments(data.data)
      }
    }

    fetchDashboardSummary()
    fetchDashboardActiveInvestments()
  }, [])

  const closedPortfolio: any = {
    averageReturn: 'N/A',
    currentInvestmentValue: 'N/A',
    investments: [],
  }

  const watchlistPortfolio: any = {
    projectedReturn: 'N/A',
    watchlistOpportunities: [],
  }

  const handleActiveViewChange = (newView: View) => {
    setActiveView(newView)
  }

  const handleActiveInvestmentChange = (newInvestment: string) => {
    setActiveInvestment(newInvestment)
  }

  return (
    <>
      {activeView === 'portfolioSummary' && (
        <DashboardPresenter
          summaryProps={summary}
          activeInvestments={activeInvestmentsNew}
          closedInvestments={closedPortfolio}
          watchlistInvestments={watchlistPortfolio}
          changeActiveView={handleActiveViewChange}
          changeActiveInvestment={handleActiveInvestmentChange}
        />
      )}
      {activeView === 'activeInvestment' && (
        <ActiveInvestmentPresenter
          activeInvestment={
            activeInvestment === null
              ? null
              : (activeInvestmentsNew.investments?.find(
                  (item: any) => item.opportunity_title === activeInvestment,
                ) as DashboardActiveInvestment)
          }
          otherInvestments={
            activeInvestment === null
              ? null
              : activeInvestmentsNew.investments?.filter(
                  (item: any) => item.opportunity_title !== activeInvestment,
                )
          }
          handleActiveInvestmentChange={(newInvestment: string) =>
            setActiveInvestment(newInvestment)
          }
        />
      )}
    </>
  )
}

export { DashboardContainerNew }
