import React from 'react'
import SectionHeader from './section-header'
import { Span } from '../../lib/components'
import { DashboardOpportunityCard } from './opportunity-card'
import { trimText } from '../../lib/utils/helpers'
import { useNavigate } from 'react-router-dom'

const WatchlistPortfolio = ({ projectedReturn, watchlistOpportunities }: any) => {
  const navigate = useNavigate()
  return (
    <div className='dashboard-container__watchlist-section'>
      <SectionHeader
        sectionName='Watchlist Portfolio'
        sectionHelperValues={[{ name: 'Projected Avg Return', value: projectedReturn }]}
      />
      <Span text='' variant='thin'>
        You currently have {watchlistOpportunities.length} opportunities in your watchlist
      </Span>

      {watchlistOpportunities.length > 0 &&
        watchlistOpportunities?.map((opportunity: any) => {
          const {
            opportunity_id,
            opportunity_slug,
            asset_class,
            currency_code,
            amount,
            opportunity_title,
            short_opportunity_summary,
            investment_return,
            ticket_size,
            images,
            cover_image,
            raise_goal,
          } = opportunity
          return (
            <DashboardOpportunityCard
              key={opportunity_id}
              opportunityCover={cover_image ?? images[0]}
              opportunityName={opportunity_title}
              opportunitySummary={trimText(short_opportunity_summary, 150)}
              opportunitySlug={opportunity_slug}
              opportunityId={opportunity_id}
              irr={investment_return}
              assetClass={asset_class}
              ticketSize={ticket_size}
              currencyCode={currency_code}
              investmentAmount={amount}
              raiseGoal={raise_goal}
              handleClick={() => {
                navigate(`/opportunity/${opportunity_slug}/${opportunity_id}`)
              }}
            />
          )
        })}
    </div>
  )
}

export { WatchlistPortfolio }
