import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  H2,
  H4,
  Span,
  P,
  Flex,
  FlexCenter,
  Input,
  Button,
  InvestIconHIW,
  EarnIconHIW,
  RedeemIconHIW,
} from '../../lib/components'
import { OpportunityCard } from '../../lib/components/OpportunityCard'
import { RootState } from '../../store'
import { calculateCommittedPercentage, trimText } from '../../lib/utils/helpers'
import { OpportunityCardProps, homeDetails } from '../../lib/types'
import LearnMoreSection from './learn-more-section'
import './home.scss'

const howWorks = [
  {
    title: 'Invest',
    Icon: InvestIconHIW,
    subTitle:
      'Pick the opportunity you would like to invest in. Subscribe to the offer and submit your payment to Credibila. If all looks good, your application will be accepted  within 4 business hours and you will be notified by our team on your registered email id',
  },
  {
    title: 'Earn',
    Icon: EarnIconHIW,
    subTitle:
      'During your investment journey, you will accrue returns aligned with the specific terms of the Opportunity you have invested in. Your returns, will be transferred directly to your bank account and you can track them in your portfolio as well',
  },
  {
    title: 'Redeem',
    Icon: RedeemIconHIW,
    subTitle:
      'During your investment journey with Credibila, you will gain returns aligned with the specific terms of the Opportunity you have invested in',
  },
]

const credibilaStory = {
  title: 'Our Story',
  content:
    'The Credibila business model was expertly crafted by the visionary founding team, who recognized a golden opportunity to revolutionize the investment landscape. With a mission to make investing not just accessible, but truly empowering, they created a platform where opportunities shine brightly on the website, inviting everyone to explore and engage, with the ability to invest from as little as US $1000. Prospective investors can effortlessly evaluate potential gains and risks, investing with the ease of a single click. Understanding the importance of flexibility, many opportunities offer the freedom of early redemption. This forward-thinking approach led Credibila’s dynamic management to establish the company as an esteemed Investment Dealer, expertly facilitating securities transactions and paving the way for a brighter financial future for all.',
}

interface HomePresenterProps {
  handleButtonClick: () => void
  expand: boolean
  handleProjectClick: (projectSlug: string, id: number) => void
  projects: any
  homeData: homeDetails
}

const HomePresenter = React.forwardRef<HTMLDivElement, HomePresenterProps>(
  ({ handleProjectClick, projects }) => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const { authToken } = useSelector((state: RootState) => state.investor)
    const loggedIn = authToken && authToken

    const navigateToFAQ = () => navigate('/faq')
    const coverContents = [
      {
        title: 'Globally curated investment opportunities',
        description: 'Access carefully selected investments with high potential returns',
      },
      {
        title: 'Start investing with as little as US $1000',
        description: 'Opening doors to opportunities for everyone',
      },
      {
        title: 'Proven expertise',
        description:
          'Human relationship manager with extensive experience in crafting low-risk, high-reward deals across various asset classes',
      },
      {
        title: 'Exclusive access',
        description:
          'Gain entry to opportunities typically reserved for large institutional investors',
      },
    ]

    return (
      <>
        <div className='homepage-cover-wrapper'>
          <Flex variant='row-center'>
            <div className='homepage-header'>
              <H2 text='Welcome to Credibila' />
              <H4 text='Expertly-Crafted Investments, Accessible to All' classes={['mb-32']}></H4>
            </div>
          </Flex>
          <div className='homepage-cover'>
            {coverContents.map((content, idx) => (
              <div key={idx} className='homepage-cover-card'>
                <img
                  src='https://gbc-credibila-assets.s3.amazonaws.com/images/home-cover-content-icon.svg'
                  alt='home-cover-icon'
                />
                <div>
                  <h3>{content.title}</h3>
                  <span>{content.description}</span>
                </div>
              </div>
            ))}
          </div>
          {!loggedIn && (
            <FlexCenter classes={[' mb-32']}>
              <Flex classes={['homepage-signup-container']}>
                <span>Start investing today</span>
                <Input
                  name='email'
                  placeholder='Enter your email address...'
                  type='text'
                  handleChange={(e) => setEmail(e.target.value)}
                  required
                  value={email}
                />
                <Button
                  text='Sign Up'
                  classes={['homepage-signup-button']}
                  handleClick={() => navigate(`/signup/${email}`)}
                  variant='primary-btn'
                />
              </Flex>
            </FlexCenter>
          )}
          <div className='homepage-cover-background'></div>
        </div>

        {/* Why Credibila */}
        <Flex classes={['column-center', 'why-credibila-wrapper']}>
          <H2 text='Why Credibila?' />
          <p className='basic mt-24 center why-credibila-wrapper__content regular'>
            Hey there! Credibila is super excited to have all you retail investors jump in and join
            us. You can start investing with just US $1000, how cool is that? It’s really easy to
            check out the potential returns and risks, and you can invest with just a click. We know
            flexibility is key, so lots of opportunities come with early redemption options. So what
            are you waiting for? Go grab those <Link to={'/opportunities'}>Opportunities</Link>!
          </p>
        </Flex>

        {/* Our Story */}
        <Flex classes={['column-center', 'story-wrapper']}>
          <H2 text={credibilaStory.title} />
          <P classes={['mt-24', 'center', 'story-wrapper__story']} text={credibilaStory.content} />
        </Flex>

        <FlexCenter classes={['mt-96', 'how-it-works-wrapper']}>
          <H2 text='How it Works?' classes={['how-it-works-wrapper-heading']} />
          <Span
            classes={['how-it-works-wrapper-subheading']}
            text='We believe that investing should be simple. At Credibila, we use technology and smarter workflows to handle the routine tasks, allowing you to enjoy the journey of growing your wealth.'
          />
          <Flex variant='row' classes={['how-it-works-cards-container']}>
            {howWorks &&
              howWorks.map(({ title, subTitle, Icon }) => (
                <div key={title} className='how-it-works-card'>
                  <Flex variant='column-center'>
                    <div className='how-it-works-card-icon'>
                      <Icon width='250' height='200' />
                    </div>
                    <Span variant='bold' classes={['how-it-works-card-heading']} text={title} />
                    <Span classes={['how-it-works-card-subheading']} text={subTitle} />
                  </Flex>
                </div>
              ))}
          </Flex>
        </FlexCenter>

        <FlexCenter classes={['mt-96', 'just-launched-wrapper']}>
          <H2 text='Just Launched' />
          <Flex variant='row' classes={['just-launched-card-container']}>
            <div className='project-cards-container'>
              {projects.length > 0 ? (
                projects.map(
                  ({
                    id,
                    opportunity_title,
                    opportunity_slug,
                    closing_date,
                    opportunity_summary,
                    total_investment_amount,
                    raise_goal,
                    images,
                    investment_return,
                    asset_class,
                    ticket_size,
                    currency_code,
                    cover_image,
                    short_opportunity_summary,
                  }: OpportunityCardProps) => (
                    <OpportunityCard
                      key={id}
                      id={id}
                      projectSlug={opportunity_slug}
                      closingDate={closing_date}
                      projectName={opportunity_title}
                      projectSummary={
                        short_opportunity_summary ?? trimText(opportunity_summary, 100)
                      }
                      commitedPercentage={
                        total_investment_amount
                          ? calculateCommittedPercentage(
                              String(total_investment_amount) || '',
                              String(raise_goal) || '',
                            )
                          : '0%'
                      }
                      irr={String(investment_return)}
                      projectCover={cover_image ?? images[0]}
                      assetClass={asset_class}
                      ticketSize={String(ticket_size)}
                      handleClick={handleProjectClick}
                      isHighCommitenceProject={false}
                      currencyCode={currency_code}
                    />
                  ),
                )
              ) : (
                <Span variant='thin' classes={['left']} text='No Opportunities Found' />
              )}
            </div>
          </Flex>
        </FlexCenter>

        <Flex variant='column' classes={['benefit-container-wrapper', 'mt-96']}>
          <div className='benefit-container-wrapper-heading'>
            <H2 text='Learn more' />
            <div>
              <Button
                classes={['auto-btn-width']}
                text='Have more questions?'
                variant='primary-btn'
                handleClick={navigateToFAQ}
              />
            </div>
          </div>
          <LearnMoreSection />
        </Flex>
      </>
    )
  },
)

HomePresenter.displayName = 'HomePresenter'
export { HomePresenter }
