import React from 'react'
import SectionHeader from './section-header'
import { Span } from '../../lib/components'
import { ActiveInvestmentCard } from './active-investment-card'
import { displayDollar } from '../../lib/utils/helpers'

interface InvestmentProps {
  opportunity_title: string
  cover_image: string
  values: { date: string; return: number; investment?: number }[]
}

interface Props {
  averageReturn: number
  currentInvestmentValue: string
  investments: InvestmentProps[]
  changeActiveView: (view: string) => void
  changeActiveInvestment: (view: string) => void
}

const ActiveInvestments = ({
  averageReturn,
  currentInvestmentValue,
  investments,
  changeActiveView,
  changeActiveInvestment,
}: Props) => {
  return (
    <div className='dashboard-container__active-investments-section'>
      <SectionHeader
        sectionName='Active Investments'
        sectionHelperValues={[
          { name: 'Avg Return', value: averageReturn === 0 ? 'N/A' : `${averageReturn}%` },
          {
            name: 'Current Investment Value*',
            value:
              averageReturn === 0 ? 'N/A' : displayDollar(currentInvestmentValue.toString(), 'USD'),
          },
        ]}
      />
      <Span text='' variant='thin'>
        You currently have <Span text={`${investments.length} active`} variant='bold' /> investments
      </Span>

      <div className='dashboard-container__active-investments-section__investments-cards-section'>
        {investments.length !== 0 &&
          investments.map((investment: InvestmentProps, idx: number) => {
            return (
              <ActiveInvestmentCard
                key={idx}
                {...investment}
                changeActiveView={changeActiveView}
                changeActiveInvestment={changeActiveInvestment}
              />
            )
          })}
      </div>
    </div>
  )
}

export { ActiveInvestments }
