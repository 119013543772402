/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showBanner } from '../../global-state/banner-slice'
import { TrustTypeProps } from '../../lib/types'
import { TrustType } from '../../lib/utils/message'
import { kycServices } from '../../services'
import { RootState } from '../../store'
import { KYCPresenter } from './kyc-presenter'
import { updateConfiguration } from '../../global-state/investor-slice'

const KYCContainer = () => {
  const dispatch = useDispatch()
  const [entityType, setEntityType] = useState('Individual')
  const [fetchedData, setFetchedData] = useState<any>([])
  const [files, setFiles] = useState<any>([])
  const [trustType, setTrustType] = useState<TrustTypeProps | undefined>(undefined)

  const { authToken, userId, kycStatus } = useSelector((state: RootState) => state.investor)
  const { isConfirm } = useSelector((state: RootState) => state.confirmModel)
  const [acceptedKYCAccuracy, setAcceptedKYCAccuracy] = useState(false)
  const [kycStatusData, setKycStatusData] = useState<string>(kycStatus || 'PENDING')
  const [noOfKyc, setNoOfKyc] = useState<number>(0)

  useEffect(() => {
    const getKYC = async () => {
      // const response: any = await kycServices.getKYC(userId.toString(), {
      //   Authorization: authToken ? authToken : '',
      // });
      const response: any = await kycServices.getKYC_v1(userId.toString(), {
        Authorization: authToken ? authToken : '',
      })
      if (!!response && response.data) {
        setKycStatusData(response.data.status ? response.data.status : '')
        setNoOfKyc(response.data.noOfKyc)
        setFetchedData([{}])
        if (response.data.investorType) setEntityType(response.data.investorType)
        setAcceptedKYCAccuracy(response.data.acceptedKYCAccuracy)
        if (response.data.trustType) handleRadioChange(response.data.trustType)
        if (response.data.status) {
          dispatch(
            updateConfiguration({
              name: 'kycStatus',
              value: response.data.status,
            }),
          )
          if (response.data.status !== kycStatus && response.data.status === 'APPROVED')
            dispatch(showBanner({ text: 'KYC Approved', variant: 'success-banner' }))
        }
      }
    }
    getKYC()
  }, [])

  useEffect(() => {
    const handleSubmit = async () => {
      try {
        if (files.length > 0 && fetchedData.length > 0) {
          const updateData: any = {
            userId: userId,
            documents: files,
            kycStatus: 'SENT_FOR_APPROVAL',
          }
          const response: any = await kycServices.kycUpdate(updateData, {
            Authorization: authToken ? authToken : '',
          })
          if (!!response && response.status === 200) {
            dispatch(
              updateConfiguration({
                name: 'kycStatus',
                value: 'SENT_FOR_APPROVAL',
              }),
            )
            dispatch(
              showBanner({
                text: 'KYC documents sent for approval',
                variant: 'success-banner',
              }),
            )
            getKYC()
            setFiles([])
          }
          if (!!response && response.response && response.response.status) {
            dispatch(
              showBanner({
                text: `${response.response.data.message}`,
                variant: 'error-banner',
              }),
            )
          }
        }
        if (fetchedData.length === 0 && files.length > 0) {
          const data: any = {
            acceptedTNC: true,
            acceptedNDA: true,
            acceptedKYCAccuracy: true,
            investorType: entityType,
            kycStatus: 'SENT_FOR_APPROVAL',
            documents: files,
          }
          if (trustType) {
            data.trustType = trustType.value
          }
          const response: any = await kycServices.kycSubmit(data, {
            Authorization: authToken ? authToken : '',
          })
          if (!!response && response.status === 200) {
            dispatch(
              updateConfiguration({
                name: 'kycStatus',
                value: 'SENT_FOR_APPROVAL',
              }),
            )
            dispatch(
              showBanner({
                text: 'KYC documents sent for approval',
                variant: 'success-banner',
              }),
            )
            getKYC()
            setFiles([])
          }
          if (!!response && response.response && response.response.status) {
            dispatch(
              showBanner({
                text: `${response.response.data.message}`,
                variant: 'error-banner',
              }),
            )
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (isConfirm && acceptedKYCAccuracy) handleSubmit()
  }, [isConfirm])

  const getKYC = async () => {
    const response: any = await kycServices.getKYC_v1(userId.toString(), {
      Authorization: authToken ? authToken : '',
    })
    if (!!response && response.data) {
      setKycStatusData(response.data.status ? response.data.status : '')
      setNoOfKyc(response.data.noOfKyc)
      setFetchedData([{}])
      if (response.data.investorType) setEntityType(response.data.investorType)
      setAcceptedKYCAccuracy(response.data.acceptedKYCAccuracy)
      if (response.data.trustType) handleRadioChange(response.data.trustType)
      if (response.data.status === 'APPROVED') {
        dispatch(updateConfiguration({ name: 'kycStatus', value: 'APPROVED' }))
        dispatch(showBanner({ text: 'KYC Approved', variant: 'success-banner' }))
      }
    }
  }

  const handleRadioChange = (val: string) => {
    let trustTypeObj: TrustTypeProps | undefined = undefined
    TrustType.forEach((item: TrustTypeProps) => {
      if (item.value === val) {
        trustTypeObj = item
      }
    })
    if (trustTypeObj) {
      setTrustType(trustTypeObj)
    }
  }

  return <KYCPresenter getKYC={getKYC} kycStatusData={kycStatusData} noOfKyc={noOfKyc} />
}

export { KYCContainer }
