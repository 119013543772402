import React, { ReactNode, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Span, Flex, FlexCenter, GeneralIcon, H1, ExpandMoreIcon } from '../../lib/components'
import './investmentEssentials.scss'

type faqKey = {
  title: string
  subTitle: string
  icon: ReactNode
  details: { question: string; answer?: string; content?: React.ReactNode }[]
}

interface faqType {
  [key: string]: {
    title: string
    subTitle: string
    icon: ReactNode
    details: { question: string; answer?: string; content?: React.ReactNode }[]
  }
}

interface faqAccordionProps {
  question: string
  answer?: string
  content?: React.ReactNode
  isActive: boolean
  setIsActive: (val: boolean) => void
}

const faqItems: faqType = {
  'General Career-Related Questions': {
    title: 'General Career-Related Questions',
    subTitle: 'Learn more about Credibila platform here under this category.',
    details: [
      {
        question: 'What attracted you to a career in fund administration and accounting?',
        answer:
          'Growing up in Nigeria, career choices were limited to traditional professions like doctor, engineer, lawyer, or accountant. I chose accounting due to personal preferences and developed a passion for fund administration through evolving opportunities and challenges. Over the years, this career has allowed me to interact with diverse aspects of business and contribute to organizational efficiency.',
      },
      {
        question: 'How has fund accounting and administration changed since the financial crisis?',
        answer:
          'The financial crisis led to major shifts in the industry, including stricter regulations, heightened scrutiny of fund managers, and reliance on technology to ensure transparency and accuracy. Functions have also shifted from in-house to outsourcing, creating opportunities for specialized fund administrators.',
      },
      {
        question: 'How has technology improved fund administration in recent years?',
        answer:
          'Technology has revolutionized fund administration by reducing errors, ensuring compliance, and enabling real-time access to data. Integrated software systems consolidate processes, cloud computing offers scalability, AI enhances data analysis and fraud detection, and blockchain ensures transparency and security in fund transactions.',
      },
      {
        question:
          'What advice would you give to those starting a career in fund accounting or law?',
        answer:
          'Focus on what excites you and aligns with your long-term career goals. For accounting, obtain professional qualifications like ACCA, CIMA, or CFA based on your interests. For law, gain experience through internships, vacation schemes, or apprenticeships, and aim to secure a training contract with a reputable firm. Building a solid foundation through qualifications and real-world exposure is key.',
      },
      {
        question: 'Are there graduate or internship opportunities in fund accounting or law?',
        answer:
          'Yes, opportunities exist in both fields. Programs like the "10,000 Interns" initiative are excellent for fund accounting. For law, many firms offer vacation schemes and apprenticeship routes for aspiring solicitors. Some companies also provide trainee positions to help graduates transition into the professional world.',
      },
    ],
    icon: <GeneralIcon />,
  },

  'Fund Administration and Legal Aspects': {
    title: 'Fund Administration and Legal Aspects',
    subTitle: 'Learn more about Credibila platform here under this category.',
    details: [
      {
        question: 'How have regulations evolved post-financial crisis?',
        answer:
          'Post-crisis, regulations emphasize investor protection, liquidity risk management, and fair redemption policies. Legal firms and compliance teams play a critical role in interpreting and implementing these guidelines to mitigate future risks.',
      },
      {
        question: 'What is the role of fund administrators during times of market volatility?',
        answer:
          'Fund administrators ensure valuation accuracy, manage liquidity, and verify investor credentials (e.g., AML and KYC checks). They act according to contracts, ensure compliance with regulations, and often rely on board approvals for key decisions like redemptions.',
      },
      {
        question:
          'Are there differences in fund administration for various types of funds (e.g., property, emerging markets)?',
        answer:
          'While the underlying standards and checks remain consistent, the treatment of funds may vary based on their specific goals and regulatory requirements. Property funds, for example, often face unique liquidity challenges compared to equity funds.',
      },
      {
        question: 'What happens when a fund collapses or investors face losses?',
        answer:
          'Investors can seek legal redress if there is evidence of a breach of contract, negligence, or non-compliance. The legal process involves identifying responsible parties, such as fund administrators or custodians, and pursuing claims based on contractual and regulatory obligations.',
      },
      {
        question: 'What responsibilities do fund custodians have during distress or liquidation?',
        answer:
          'Custodians are responsible for the safekeeping of assets. In cases of fund collapse, insolvency practitioners may attempt to recover assets or pursue legal action for breaches. Custodians play a critical role in ensuring that assets are secure and accounted for.',
      },
      {
        question: 'What role do legal firms play in dealing with fund flows?',
        answer:
          'Legal firms, alongside compliance teams, draft and review investment guidelines, fund prospectuses, and operational documentation to ensure regulatory compliance. They also assist in resolving disputes and managing litigation when issues arise.',
      },
    ],
    icon: <GeneralIcon />,
  },

  'Technology and Innovations': {
    title: 'Technology and Innovations',
    subTitle: 'Learn more about Credibila platform here under this category.',
    icon: <GeneralIcon />,
    details: [
      {
        question: 'What technological advancements have transformed fund administration?',
        content: (
          <>
            Key advancements include:
            <ul>
              <li>
                <strong>Integrated Software Systems</strong> - Consolidate processes, reducing human
                error and enhancing operational efficiency.
              </li>
              <li>
                <strong>Cloud Computing</strong> - Offers scalable, secure, and remote
                administration.
              </li>
              <li>
                <strong>AI in Auditing</strong> - Analyzes large data volumes, detects anomalies,
                and streamlines audits.
              </li>
              <li>
                <strong>Blockchain</strong> - Ensures transparency and reduces fraud risks through
                decentralized ledgers.
              </li>
              <li>
                <strong>Real-time Reporting</strong> - Automates communication, providing instant
                updates to stakeholders.
              </li>
            </ul>
          </>
        ),
      },
      {
        question: 'Does technology introduce risks, such as reliance on single platforms?',
        answer:
          'While reliance on single platforms may seem risky, robust systems like SS&C mitigate these risks through multi-factor authentication and secure access controls. Centralized platforms also enhance efficiency and streamline processes.',
      },
      {
        question: 'How does SS&C differentiate itself in terms of technology and automation?',
        answer:
          'SS&C provides an end-to-end solution for private equity, hedge funds, and real estate. Their integrated platform handles everything from AML and KYC checks to reporting and back-office operations, making them a one-stop shop for fund administration.',
      },
      {
        question: 'How are AI tools being responsibly integrated into fund administration?',
        answer:
          'AI is used to detect fraud, streamline audits, and enhance decision-making. However, ethical use and compliance with regulations are critical to ensure AI serves as a complement rather than a replacement for human judgment.',
      },
    ],
  },

  'Consumer and Regulatory Concerns': {
    title: 'Consumer and Regulatory Concerns',
    subTitle: 'Learn more about Credibila platform here under this category.',
    icon: <GeneralIcon />,
    details: [
      {
        question: 'Will consumer duty responsibilities prevent fund managers from risky practices?',
        answer:
          'Consumer duty adds pressure for compliance but is part of broader principles designed to ensure ethical practices. Combined with other regulations, these duties guide fund managers toward fair and responsible decision-making.',
      },
      {
        question: 'How does regulation address liquidity in times of market stress?',
        answer:
          'Regulations require funds to implement robust liquidity management policies, including gating provisions, redemption penalties, and stress tests, to ensure fair treatment of investors during volatile periods.',
      },
    ],
  },

  'Miscellaneous Questions': {
    title: 'Miscellaneous Questions',
    subTitle: 'Learn more about Credibila platform here under this category.',
    icon: <GeneralIcon />,
    details: [
      {
        question: 'Do fund administration systems support forecasting in treasury operations?',
        answer:
          'This depends on the specific system. Some platforms offer treasury forecasting capabilities, but it’s best to consult with the service provider for detailed information.',
      },
      {
        question: 'How do legal ownership and custody work during liquidation?',
        answer:
          'Custodians are generally responsible for the safekeeping of assets. In liquidation, they must ensure assets are managed properly and assist insolvency practitioners in recovering or valuing assets for the benefit of investors.',
      },
    ],
  },
}

const Accordion = ({ question, answer, content, isActive, setIsActive }: faqAccordionProps) => (
  <div className='accordion'>
    <div className='accordion-item'>
      <div className='accordion-title' onClick={() => setIsActive(!isActive)}>
        <div>{question}</div>
        <div className='accordion-icon'>
          <ExpandMoreIcon classes={isActive ? ['expand-more-icon'] : []} />
        </div>
      </div>
      {isActive && <div className='accordion-content'>{content ?? answer}</div>}
    </div>
  </div>
)

const InvestmentEssentialsFAQDetails = () => {
  const [selected, setSelected] = useState<faqKey | null>(null)
  const [isActive, setIsActive] = useState(false)
  const [activeIndex, setIsActiveIndex] = useState<number | null>(null)
  const navigate = useNavigate()
  const { title } = useParams()

  const handleClick = (faq: faqKey) => {
    navigate(`/investment-essentials/faq-details/${faq.title}`)
    setSelected(faq)
  }

  useEffect(() => {
    if (title) {
      setSelected(faqItems[title])
    }
  }, [faqItems])

  return (
    <>
      <Flex classes={['faq-content-wrapper']}>
        <>
          <Flex classes={['faq-navigation']}>
            <div className='pointer' onClick={() => navigate('/home')}>
              <Span text='Home' />
            </div>
            <Span classes={['breadcrumbs-arrow']} text='>' />
            <div className='pointer' onClick={() => navigate('/investment-essentials')}>
              <Span text='Investment Essentials' />
            </div>
            <Span classes={['breadcrumbs-arrow']} text='>' />
            <div>
              <Span classes={['faq-selected-tab']} text={title ? title : ''} />
            </div>
          </Flex>
        </>
      </Flex>
      <FlexCenter classes={['faq-details-heading mt-24']}>
        <H1 text='Investment essentials FAQ' />
      </FlexCenter>
      <Flex variant='space-between' classes={['faq-container', 'mt-72', 'mb-82']}>
        <Flex variant='column' classes={['faq-side-navigation']}>
          <>
            {Object.keys(faqItems).map(
              (key: string) =>
                key && (
                  <div
                    key={key}
                    className={
                      faqItems[key].title === selected?.title
                        ? 'faq-navigation-list faq-selected-container'
                        : 'faq-navigation-list'
                    }
                    onClick={() => handleClick(faqItems[key])}
                  >
                    <Flex variant='row' classes={['faq-navigation-list-container']}>
                      <div className='faq-details-icon'>{faqItems[key].icon as ReactNode}</div>
                      <Span
                        classes={['faq-list-item', 'pointer']}
                        text={faqItems[key].title as string}
                      />
                    </Flex>
                  </div>
                ),
            )}
          </>
        </Flex>
        <Flex variant='column' classes={['faq-accordion', 'ml-59']}>
          {selected?.details &&
            selected?.details.map(
              (
                {
                  question,
                  answer,
                  content,
                }: { question: string; answer?: string; content?: React.ReactNode },
                index,
              ) => (
                <Accordion
                  key={index}
                  question={question}
                  answer={answer}
                  content={content}
                  isActive={activeIndex === index ? isActive : false}
                  setIsActive={(val: boolean) => {
                    setIsActiveIndex(index)
                    return setIsActive(val)
                  }}
                />
              ),
            )}
        </Flex>
      </Flex>
    </>
  )
}

export { InvestmentEssentialsFAQDetails }
