import React, { useEffect, useState } from 'react'
import { displayDollar } from '../../lib/utils/helpers'
import { H2, P } from '../../lib/components'
import { LineChart, Line, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

interface ChartCardProps {
  data: any[]
}

const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { currentInvestmentValue, date } = payload[0].payload

    return (
      <div
        className='custom-tooltip'
        style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}
      >
        <p>{`Date: ${date}`}</p>
        <p>{`Current Value: ${displayDollar(
          currentInvestmentValue.toString(),
          'USD',
          2,
          currentInvestmentValue / 100 < 10000,
        )}`}</p>
      </div>
    )
  }

  return null
}

const formatYAxis = (value: number) => {
  const forceStandardFormat = value / 100 < 10000
  return displayDollar(value.toString(), 'USD', 0, forceStandardFormat)
}

const ChartCard = ({ data }: ChartCardProps) => {
  const [investmentValue, setinvestmentValue] = useState('0')
  const [chartYRange, setChartYRange] = useState({ min: 0, max: 1000 })

  useEffect(() => {
    setinvestmentValue(data[data.length - 1]?.currentInvestmentValue ?? 0)

    // Calculating max range of Y-axis
    const minValue = Math.min(...data.map((item) => item.currentInvestmentValue))
    const maxValue = Math.max(...data.map((item) => item.currentInvestmentValue))

    setChartYRange({
      min: minValue - 0.1 * minValue,
      max: maxValue + 0.1 * maxValue,
    })
  }, [data])

  return (
    <div className='chart-card'>
      <P text={<strong>Current Investment Value*</strong>} textSize='small' />
      <H2
        text={displayDollar(investmentValue, 'USD', 0, parseFloat(investmentValue) / 100 < 10000)}
        classes={['mt-4', 'mb-20']}
      />
      <ResponsiveContainer width='100%' height={250}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray='3 5' />
          <YAxis
            axisLine={false}
            domain={[chartYRange.min, chartYRange.max]}
            tickFormatter={formatYAxis}
          />

          <Tooltip content={<CustomTooltip />} />
          <Line
            type='monotone'
            dataKey='currentInvestmentValue'
            stroke='#8884d8'
            activeDot={{ r: 8 }}
            name=''
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export { ChartCard }
