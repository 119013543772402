import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  MFA,
  SignupContainer,
  LoginContainer,
  WelcomeContainer,
  VerifyEmailContainer,
  ForgotPasswordContainer,
  ResetPasswordContainer,
  ResetInstructionsContainer,
  ProjectListContainer,
  NotFound,
  AccessDenied,
  AcceptNDAContainer,
  FAQ,
  FAQdetails,
  HomeContainer,
  ProfileContainer,
  InvestContainer,
  DashboardContainer,
  DashboardContainerNew,
  PaymentContainer,
  SavedProjectsContainer,
  PrivacyPolicyContainer,
  TermsAndConditionsContainer,
  OpportunityDetailContainer,
  OpportunityListContainer,
  CloseTabComponent,
  ContactUsContainer,
  ResendVerificationContainer,
  AboutUs,
  InvestmentEssentials,
  InvestmentEssentialsFAQDetails,
} from './pages'
import { Navbar, Footer, WarningIcon, Anchor, ContactUsModal } from './lib/components'
import { PublicRoute, PrivateRoute, KYC, NO_NDA, NDA, EmailVerified } from './route-permissions'
import { RootState } from './store'
import ScrollToTop from './utils/ScrollToTop'
import { userServices } from './services'
import { createConfiguration, resetConfiguration } from './global-state/investor-slice'
import { getAuthToken, removeAuthToken } from './lib/utils/helpers'
import './App.scss'

const App = () => {
  const { authToken, isEmailVerified, kycStatus } = useSelector(
    (state: RootState) => state.investor,
  )
  const [showContactUsModal, setShowContactUsModal] = useState(false)
  const dispatch = useDispatch()
  const isLoggedIn = authToken && authToken

  const resendVerificationEmailHandler = async () => {
    const data: any = await userServices.userLogout_v1({
      Authorization: authToken ? authToken : '',
    })
    if (data.status === 200) {
      dispatch(resetConfiguration())
      window.location.href = 'resend-verification-email'
    }
  }

  useEffect(() => {
    const checkSession = async () => {
      const authToken = getAuthToken()

      if (authToken) {
        try {
          const session = await userServices.getSession({
            Authorization: authToken,
          })

          if (!session.data.isActiveSession) {
            await userServices.userLogout_v1({ Authorization: authToken })
            dispatch(resetConfiguration())
            removeAuthToken()
            window.location.reload()
          } else {
            dispatch(
              createConfiguration({
                authToken,
                isEmailVerified: session.data.is_verified,
                acceptedNDA: session.data.acceptedNDA,
                kycStatus: session.data.kycStatus,
                ...session.data,
              } as any),
            )
          }
        } catch (error) {
          console.error('Failed to check session:', error)
        }
      } else {
        dispatch(resetConfiguration())
      }
    }

    checkSession()
  }, [])

  return (
    <>
      <BrowserRouter>
        {isLoggedIn && !isEmailVerified && (
          <div className='verify-email-top-warning-banner'>
            <WarningIcon type='warning-banner' />{' '}
            <span>
              Please verify your email address. Check your inbox/spam folder for the verification
              email. Click{' '}
              <span onClick={() => resendVerificationEmailHandler()}>
                {' '}
                <Anchor variant='basic-anchor' text='here' href='#' />{' '}
              </span>{' '}
              to resend the verification link.
            </span>
          </div>
        )}
        {isLoggedIn && isEmailVerified && kycStatus !== 'APPROVED' && (
          <div className='verify-email-top-warning-banner'>
            <WarningIcon type='warning-banner' />{' '}
            <span> Please complete the KYC to start investing.</span>
            <Anchor variant='basic-anchor' text={'Click here'} href='/profile/kyc' />
          </div>
        )}
        <Navbar setShowContactUsModal={(show) => setShowContactUsModal(show)} />
        <div className='App'>
          <ScrollToTop />
          <Routes>
            <Route
              path='signup'
              element={
                <PublicRoute>
                  <SignupContainer />
                </PublicRoute>
              }
            />
            <Route
              path='signup/:email'
              element={
                <PublicRoute>
                  <SignupContainer />
                </PublicRoute>
              }
            />
            <Route
              path='login'
              element={
                <PublicRoute>
                  <LoginContainer />
                </PublicRoute>
              }
            />
            <Route
              path='welcome'
              element={
                <PublicRoute>
                  <WelcomeContainer />
                </PublicRoute>
              }
            />
            <Route
              path='verify-email'
              element={
                <PublicRoute>
                  <VerifyEmailContainer />
                </PublicRoute>
              }
            />
            <Route
              path='forgot-password'
              element={
                <PublicRoute>
                  <ForgotPasswordContainer />
                </PublicRoute>
              }
            />
            <Route
              path='resend-verification-email'
              element={
                <PublicRoute>
                  <ResendVerificationContainer />
                </PublicRoute>
              }
            />
            <Route
              path='reset-instructions'
              element={
                <PublicRoute>
                  <ResetInstructionsContainer />
                </PublicRoute>
              }
            />
            <Route
              path='reset-password'
              element={
                <PublicRoute>
                  <ResetPasswordContainer />
                </PublicRoute>
              }
            />
            <Route path='projects' element={<ProjectListContainer />} />
            <Route path='opportunities' element={<OpportunityListContainer />} />
            <Route
              path='accept-NDA'
              element={
                <PrivateRoute perms={[NO_NDA, EmailVerified]}>
                  <AcceptNDAContainer />
                </PrivateRoute>
              }
            />
            <Route
              path='opportunity/:projectSlug/:id'
              element={
                <PrivateRoute perms={[NDA, EmailVerified]}>
                  <OpportunityDetailContainer />
                </PrivateRoute>
              }
            />
            {/* Replace /invest with invest page component */}
            <Route
              path='/invest/:projectSlug/:projectId'
              element={
                <PrivateRoute perms={[EmailVerified, KYC]}>
                  <InvestContainer />
                </PrivateRoute>
              }
            />
            <Route path='/invest/signing-complete' element={<CloseTabComponent />} />
            <Route
              path='/payment/:projectSlug/:projectId/:paymentPage'
              element={
                <PrivateRoute perms={[EmailVerified, KYC]}>
                  <PaymentContainer />
                </PrivateRoute>
              }
            />
            <Route path='/403' element={<AccessDenied />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/faq-details/:title' element={<FAQdetails />} />

            {/* Disabling investment essential FAQ route temporarily */}
            {/* <Route path='/investment-essentials' element={<InvestmentEssentials />} />
            <Route
              path='/investment-essentials/faq-details/:title'
              element={<InvestmentEssentialsFAQDetails />}
            /> */}
            <Route
              path='/contact-us'
              element={
                <PrivateRoute perms={[EmailVerified]}>
                  <ContactUsContainer />
                </PrivateRoute>
              }
            />
            <Route
              path='/MFA'
              element={
                <PublicRoute>
                  <MFA />
                </PublicRoute>
              }
            />
            <Route path='/' element={<HomeContainer />} />
            <Route path='/home' element={<HomeContainer />} />
            <Route path='/privacy_policy' element={<PrivacyPolicyContainer />} />
            <Route path='/terms_and_conditions' element={<TermsAndConditionsContainer />} />
            <Route
              path='/profile/:profileTab'
              element={
                <PrivateRoute perms={[EmailVerified]}>
                  <ProfileContainer />
                </PrivateRoute>
              }
            />
            <Route
              path='/dashboard'
              element={
                <PrivateRoute perms={[EmailVerified]}>
                  <DashboardContainer />
                </PrivateRoute>
              }
            />
            <Route
              path='/dashboard-new'
              element={
                <PrivateRoute perms={[EmailVerified]}>
                  <DashboardContainerNew />
                </PrivateRoute>
              }
            />
            <Route
              path='/saved-opportunities'
              element={
                <PrivateRoute>
                  <SavedProjectsContainer />
                </PrivateRoute>
              }
            />
            <Route path='/about' element={<AboutUs />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
      {showContactUsModal ? (
        <ContactUsModal
          active={showContactUsModal}
          subject={'Onboard'}
          handleOnClose={() => setShowContactUsModal(false)}
        />
      ) : null}
    </>
  )
}

export default App
