import React from 'react'
import { Button, Flex, H5 } from '../../lib/components'
import { ChartCard } from './chart-card'

interface InvestmentCardProps {
  opportunity_title: string
  cover_image: string
  values: { date: string; return: number; investment?: number }[]
  changeActiveView: (view: string) => void
  changeActiveInvestment: (view: string) => void
}

const ActiveInvestmentCard = ({
  opportunity_title,
  cover_image,
  values,
  changeActiveView,
  changeActiveInvestment,
}: InvestmentCardProps) => {
  return (
    <div className='dashboard-container__active-investments-section__investment-card'>
      {/* Active opportunity image and name */}
      <Flex classes={['align-center', 'mb-24']}>
        <div className='dashboard-container__active-investments-section__investment-card__image'>
          <img src={cover_image} alt='opportunity cover' />
        </div>
        <Flex classes={['ml-16']}>
          <H5 text={opportunity_title} />
        </Flex>
      </Flex>

      {/* Active opportunity card */}
      <ChartCard data={values} />

      {/* Active opportunity button section */}
      {/* <Flex
        classes={[
          'dashboard-container__active-investments-section__investment-card__button-section',
        ]}
      >
        <Button
          variant='primary-btn'
          text='View Details'
          handleClick={() => {
            changeActiveView('activeInvestment')
            changeActiveInvestment(opportunity_title)
          }}
        />
        <Button
          variant='basic-btn'
          text='Redeem'
          classes={['ml-8']}
          handleClick={() => {
            return
          }}
        />
      </Flex> */}
    </div>
  )
}

export { ActiveInvestmentCard }
