import { axiosInstance } from '.'
import { GET_DASHBOARD_ACTIVE_INVESTMENTS, GET_DASHBOARD_SUMMARY } from './endpoints'

export const dashboardServices = {
  getDashboardSummary: async (headers: { [key: string]: string }) => {
    try {
      return axiosInstance.get(GET_DASHBOARD_SUMMARY, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },

  getActiveInvestments: async (headers: { [key: string]: string }) => {
    try {
      return axiosInstance.get(GET_DASHBOARD_ACTIVE_INVESTMENTS, {
        headers: headers,
      })
    } catch (error: any) {
      return error.response
    }
  },
}
