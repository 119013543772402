/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { H4, Button } from '../../lib/components'
import { MD } from '../../lib/utils/constants'
import { useBreakpoint } from '../../lib/utils/hooks'
import { EngagementEvents, logCrmEvent } from '../../lib/utils/crmEvents'
import { RootState } from '../../store'
import './investment-details.scss'

const LegalAgreement = ({
  setAgreement,
  getInvestmentDocument,
  getSignedDocument,
  getDocumentAccessToken,
  getDocumentSigningLink,
  getDocumentDownloadLink,
  agreementLink,
}: any) => {
  const [accessToken, setAccessToken] = useState('')
  const [documentId, setDocumentId] = useState('')
  const [isButtonDisabled, setButtonDisable] = useState(false)
  const [buttonText, setButtonText] = useState('Sign Agreement')
  const [isSentForSigning, setSentForSigning] = useState(false)
  const [documentStatus, setDocumentStatus] = useState('e-Singing in progress')
  const { authToken } = useSelector((state: RootState) => state.investor)

  async function handleClick() {
    try {
      if (!isSentForSigning) {
        setButtonDisable(true)
        setButtonText('Redirecting..')

        const { signingLink, documentId } = await getDocumentSigningLink(accessToken)
        setDocumentId(documentId)

        window.open(signingLink)

        setSentForSigning(true)
        setButtonText('Refresh')
        setButtonDisable(false)
      } else {
        const document = await getInvestmentDocument(documentId)

        if (document.status === 'COMPLETED') {
          setDocumentStatus('e-Signing completed!')
          setButtonText('Download Agreement')
          setAgreement(document.document_url)

          logCrmEvent(EngagementEvents.agreement_signed, {
            Authorization: authToken ?? '',
          })
        }

        if (buttonText === 'Download Agreement') {
          setButtonDisable(true)
          const downloadLink = await getDocumentDownloadLink(accessToken, documentId)

          window.open(downloadLink)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    ;(async () => {
      const existingDocument = await getSignedDocument()

      if (existingDocument) {
        setSentForSigning(true)
        setButtonText('Download Agreement')
        setDocumentId(existingDocument.document_id)
        setAgreement(existingDocument.document_url)
      }

      const accessToken = await getDocumentAccessToken()
      setAccessToken(accessToken)
    })()
  }, [])

  const { breakpoint } = useBreakpoint()
  return (
    <>
      <div className='legal-agreement-wrapper'>
        <H4
          text={
            agreementLink
              ? 'Great! You have already signed the agreement'
              : isSentForSigning
              ? `Agreement status: ${documentStatus}`
              : 'Please click the button below to sign the agreement'
          }
        />
        {agreementLink && (
          <span>Please click the button below to download the signed agreement</span>
        )}
        {isSentForSigning && !agreementLink && (
          <span>Please click Refresh button to know the agreement status</span>
        )}
        <div>
          <Button
            classes={breakpoint && breakpoint > MD ? ['auto-btn-width'] : ['']}
            iconVariant='left'
            text={buttonText}
            isDisabled={isButtonDisabled}
            handleClick={handleClick}
            variant='basic-btn'
          />
        </div>
      </div>
    </>
  )
}

export { LegalAgreement }
